<template>
  <div>
    <b-modal
      :id="componentName"
      no-close-on-backdrop
      hide-footer
      centered
      size="sm"
      @shown="btnModal('show')"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          총판출금신청
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click.prevent="btnModal('close')"
          />
        </div>
      </template>

      <!-- Modal Body -->
      <b-card no-bod>
        <b-row>
          <b-col>
            <b-form-group
              class="font-small-3"
              label="총판명"
              label-for="main-notice"
              label-cols-md="4"
            >
              <div
                class="font-small-3"
                size="sm"
                style="color:rgb(229,166,48); margin-top: 6px"
              >
                <!--                {{ cashOutBranchName }}-->
                {{ userData.nickname }}
              </div>
            </b-form-group>
            <b-form-group
              class="font-small-3"
              label="정산 적용일자"
              label-for="main-notice"
              label-cols-md="4"
            >
              <div
                class="font-small-3"
                size="sm"
                style="color:rgb(229,166,48); margin-top: 6px"
              >
                {{ cashOutBranchDate }}
              </div>
            </b-form-group>
            <b-form-group
              class="font-small-3"
              label="출금 가능한 금액"
              label-for="main-notice"
              label-cols-md="4"
            >
              <div
                class="font-small-3"
                size="sm"
                style="color:rgb(229,166,48); margin-top: 6px"
              >
                {{ formatNumberWithCommas(withdrawableAmount) }}
              </div>
            </b-form-group>
            <b-form-group
              class="font-small-3"
              label="출금 요청중인 금액"
              label-for="main-notice"
              label-cols-md="4"
            >
              <div
                class="font-small-3"
                size="sm"
                style="color:rgb(229,166,48); margin-top: 6px"
              >
                {{ formatNumberWithCommas(requestedWithdrawAmount) }}
              </div>
            </b-form-group>
            <b-form-group
              class="font-small-3"
              label="출금 요청할 금액"
              label-for="main-notice"
              label-cols-md="4"
            >
              <b-form-input
                id="main-notice"
                v-model="cashOutBranchAmount"
                class="font-small-3 text-info"
                size="sm"
                placeholder="출금요청할 금액을 입력하세요"
                style="color:rgb(229,166,48)"
                type="number"
                :class="{ 'is-valid': cashOutBranchAmountValidateState }"
              />
              <b-form-invalid-feedback :state="cashOutBranchAmountValidateState">
                {{ cashOutBranchAmountValidateMessage }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              class="font-small-3"
              label="출금 적용 후 금액"
              label-for="main-notice"
              label-cols-md="4"
            >
              <div
                class="font-small-3"
                size="sm"
                style="color:rgb(229,166,48); margin-top: 6px"
              >
                {{ formatNumberWithCommas(leftWithdrawableAmount) }}
              </div>
            </b-form-group>
            <b-form-group
              class="font-small-3"
              label="요청 내용"
              label-for="main-notice"
              label-cols-md="4"
            >
              <b-form-textarea
                id="main-notice"
                v-model="cashOutBranchComment"
                class="font-small-3 text-info"
                size="sm"
                placeholder="특이사항이 있을경우 입력하세요"
                rows="2"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="py-1">
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="float-right"
              :disabled="!cashOutBranchAmountValidateState"
              @click="btnConfirm('BranchCashOut')"
            >
              출금신청
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              class="float-right mr-1"
              @click="btnModal('close')"
            >
              취소
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BCard, BRow, BCol, BButton,
  BForm, BFormGroup,
  BInputGroup, BFormInput, BInputGroupAppend,
  BFormInvalidFeedback, BFormValidFeedback,
  BFormSelect, BFormSelectOption,
  BTable, BPagination,
  BFormDatepicker,
  BFormTextarea,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'

import { createNamespacedHelpers } from 'vuex'
import { CREATE_BRANCH_CASHOUT_REQUEST } from '@/store/branch/mutation'
import { FETCH_BRANCH_WITHDRAWABLE_AMOUNT } from '@/store/branch/action'

import * as moment from 'moment-timezone'

const branchStore = createNamespacedHelpers('branchStore')

moment().tz('Asia/Seoul')

export default {

  name: 'BranchCashOutRequest',
  components: {
    BModal,
    BCard,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormSelect,
    BFormSelectOption,
    BTable,
    BPagination,
    BFormDatepicker,
    BFormTextarea,

  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,

      cashOutBranchDate: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'), // 정산 적용일
      cashOutBranchAmount: '',
      cashOutBranchComment: '',

      cashOutBranchAmountValidateState: null,
      cashOutBranchAmountValidateMessage: '',
    }
  },
  computed: {
    ...branchStore.mapGetters({
      fetchBranchWithdrawableAmount: 'fetchBranchWithdrawableAmount',
    }),

    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.init()
      },
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    withdrawableAmount() {
      return this.fetchBranchWithdrawableAmount?.withdrawableAmount - this.fetchBranchWithdrawableAmount?.withdrawnAmount || 0 // 출금 가능한 금액
    },
    requestedWithdrawAmount() {
      return this.fetchBranchWithdrawableAmount?.requestCash || 0 // 출금 요청 중인 금액
    },
    leftWithdrawableAmount() {
      const withdrawableAmount = this.fetchBranchWithdrawableAmount?.withdrawableAmount - this.fetchBranchWithdrawableAmount?.withdrawnAmount || 0
      const requestedWithdrawAmount = this.fetchBranchWithdrawableAmount?.requestCash || 0
      return withdrawableAmount - requestedWithdrawAmount - this.cashOutBranchAmount// 출금 적용 후 금액 계산
    },

    // 총판 출금 신청금액
    // withdrawnAmountRequested() {
    //   const requestAmount = this.fetchRequestAlarmsBranchCashout.reduce((sum, item) => ((item.requestId === this.userData.userid && item.confirmed === 'REQUEST') ? sum + item.requestCash : sum), 0)
    //   console.log('withdrawnAmountRequested: ', requestAmount)
    //   return requestAmount
    // },
    // test() {
    //   console.log('fetchBalanceBranchDailyProfit##', this.fetchBalanceBranchDailyProfit)
    //   return '테스트'
    // },
  },
  watch: {
    cashOutBranchAmount(newVal, oldVal) {
      this.fncCashOutBranchAmountValidate(newVal, oldVal)
    },
  },
  methods: {
    ...branchStore.mapActions({
      $createBranchCashoutRequest: CREATE_BRANCH_CASHOUT_REQUEST,
      $fetchBranchWithdrawableAmount: FETCH_BRANCH_WITHDRAWABLE_AMOUNT,
    }),
    // 총판 출금 신청금액 조회
    async fetchData() {
      await this.$fetchBranchWithdrawableAmount({
        site: this.siteSelected,
        userid: this.userData.userid,
      })
    },

    // 총판 출금 가능금액
    // fncWithdrawableAmount() {
    //   const branch = this.userData.role === 'admin' ? this.siteSelected : this.userData.nickname
    //   const baseDate = moment(new Date()).subtract(1, 'days').format('YYYYMMDD')
    //   console.log('withdrawableAmount##', branch, baseDate)
    //   console.log('fetchBalanceBranchDailyProfit##', this.fetchBalanceBranchDailyProfit)

    //   const withdrawableAmount = this.fetchBalanceBranchDailyProfit.find(
    //     item => item.baseDate === moment().subtract(1, 'days').format('YYYYMMDD')
    //       && item.nickname === (this.userData.role === 'admin' ? this.siteSelected : this.userData.nickname),
    //   )
    //   // const withdrawableAmount = this.fetchBalanceBranchDailyProfit.find(item => item.baseDate === moment().subtract(1, 'days').format('YYYYMMDD'))
    //   console.log('withdrawableAmount', withdrawableAmount)
    //   // return withdrawableAmount - this.cashOutBranchRequested || 0
    //   return false
    // },
    // 총판 출금 가능금액
    // fncWithdrawableAmount() {
    //   const branch = this.userData.role === 'admin' ? 'admin' : this.userData.branch
    //   const baseDate = moment(new Date()).subtract(1, 'days').format('YYYYMMDD')
    //   console.log('fncWithdrawableAmount', branch, baseDate)

    //   this.fetchBalanceBranchDailyProfit.find(item => item.baseDate === moment(new Date()).subtract(1, 'days').format('YYYYMMDD')
    //     && item.branch === (this.userData.role === 'admin' ? 'admin' : this.userData.branch))
    // },
    // 출금 요청 금액 validate
    fncCashOutBranchAmountValidate(newVal) {
      // 초기화
      this.cashOutBranchAmountValidateState = null
      this.cashOutBranchAmountValidateMessage = ''
      // 유효성 검사
      if (typeof newVal === 'number') {
        this.cashOutBranchAmountValidateState = false
        this.cashOutBranchAmountValidateMessage = '숫자를 입력바랍니다.'
        this.cashOutBranchAmount = null
      } else if (newVal < 10000) {
        this.cashOutBranchAmountValidateState = false
        this.cashOutBranchAmountValidateMessage = '출금 요청 최소금액은 10,000원 이상입니다.'
      } else if (this.leftWithdrawableAmount < 0) {
        this.cashOutBranchAmountValidateState = false
        this.cashOutBranchAmountValidateMessage = '출금 요청 금액이 출금 가능 금액을 초과합니다.'
      } else {
        this.cashOutBranchAmountValidateState = true
        this.cashOutBranchAmountValidateMessage = ''
      }
    },
    async btnConfirm(type) {
      if (type !== 'BranchCashOut') return false

      if (this.cashOutBranchAmountValidateState) {
        await this.fncShowSwal(
          'confirm',
          `총판 출금 금액( ${Number(this.cashOutBranchAmount).toLocaleString()}원)을 \n신청하시겠습니까?`,
        )
          .then(result => {
            if (result.isConfirmed) {
              try {
                this.$createBranchCashoutRequest({
                  site: this.siteSelected,
                  requestCash: Number(this.cashOutBranchAmount),
                  requsetMemo: this.cashOutBranchComment,
                })
              } catch {
                console.error()
              } finally {
                this.fncShowSwal(
                  'success',
                  '출금 신청 완료되었습니다.',
                )
                  .then(() => {
                    this.btnModal('close')
                  })
              }
            }
          })
      }
    },
    fncReset() {
      this.cashOutBranchAmount = null
      this.cashOutBranchComment = null
      this.cashOutBranchAmountValidateState = null
      this.cashOutBranchAmountValidateMessage = ''
    },

    /* [formatter] */
    formatNumberWithCommas(value) {
      if (value == null || Number.isNaN(Number(value))) {
        return value
      }

      return parseInt(value, 10).toLocaleString() // 정수로 변환 후 천 단위 쉼표 추가
    },

    // Modal
    btnModal(target) {
      if (target === 'show') {
        console.log(this.componentName, 'btnModal()', target)
        // this.fncWithdrawableAmount()
        this.fetchData()
      }
      if (target === 'close') {
        console.log(this.componentName, 'btnModal()', target)
        this.$bvModal.hide(this.componentName)
        this.fncReset()
      }
    },
    // Swal
    fncShowSwal(type, title = null, text = null) {
      const config = {
        confirm: {
          title: '변경내용을 저장하시겠습니까?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            cancelButton: 'btn btn-dark',
            confirmButton: 'btn btn-primary mr-1',
          },
        },
        check: {
          title: '변경된 내용이 없습니다. \n 다시 확인바랍니다.',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
        cancel: {
          title: '변경된 내용이 저장되지 않습니다. 정말 나가시겠습니까?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            cancelButton: 'btn btn-dark',
            confirmButton: 'btn btn-primary mr-1',
          },
        },
        success: {
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
        error: {
          title: '저장 실패',
          icon: 'error',
          text: '시스템 관리자에게 문의바랍니다.',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
      }[type]

      // 추가적으로 전달된 title 있다면 해당 설정을 덮어씁니다.
      if (title) config.title = title

      // 추가적으로 전달된 text 있다면 해당 설정을 덮어씁니다.
      if (text === null) delete config.text

      // Swal을 실행합니다.
      return Swal.fire(config)
    },
  },
}
</script>

<style>

</style>
