<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="사이트"
              label-for="branch-site"
            >
              <b-form-select
                v-if="authSite"
                id="branch-site"
                ref="branch-site"
                v-model="siteSelected"
                :options="authSite"
                class="font-small-3"
                style="color:rgb(229,166,48);"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="3"
          >
            <b-form-group
              label="총판"
              label-for="branch"
            >
              <b-form-select
                id="branch"
                ref="branch"
                v-model="branchSelected"
                :options="branchSelectOptions"
                class="font-small-3"
                style="color:rgb(229,166,48)"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
          >
            <b-input-group
              class="calendar-size font-small-3"
              style="padding-top: 1.5rem"
            >
              <b-form-datepicker
                v-model="periodFrom"
                class="align-self-center"
                boundary="window"
                locale="ko"
                placeholder="시작일"
                aria-controls="example-input"
                size="sm"
                :max="periodTo"
              />
              <div class="pl-1 pr-1">
                ~
              </div>
              <b-form-datepicker
                v-model="periodTo"
                class="align-self-center"
                boundary="window"
                locale="ko"
                placeholder="종료일"
                aria-controls="example-input"
                size="sm"
                :min="periodFrom"
              />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="ml-1"
                @click="searchData"
              >
                검색
              </b-button>
            </b-input-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="검색"
              label-for="branch-input"
              class="font-small-3"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="searchFilter"
                  type="search"
                  placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
                  style="color: #99dff1"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!searchFilter"
                    @click="searchFilter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >
      <div>
        <div class="d-flex justify-content-between">
          <div>
            <div
              class="text-info small pt-1"
              style="padding-left: 2rem"
            >
              Z: 이월되는 Z`의 값
            </div>
            <div
              class="text-info small"
              style="padding-left: 2rem"
            >
              Z`: 총판포함 하위캐쉬 + 총판포함 하위카지노캐쉬 + 총판포함 하위포인트
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <!-- 전체 펼치기 버튼 -->
            <b-button
              v-if="false"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-info"
              class="btn-icon mt-1"
              style="margin-right: 1rem"
              @click="expandAllRows"
            >
              전체 펼치기
            </b-button>

            <!-- 전체 접기 버튼 -->
            <b-button
              v-if="false"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-info"
              class="btn-icon mt-1"
              @click="collapseAllRows"
            >
              전체 접기
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-info"
              class="btn-icon mt-1"
              style="margin-right: 1rem"
              @click="btnModal('withdrawHistory')"
            >
              총판 출금내역
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="isBranchWithrawable ? 'outline-info' : 'outline-secondary'"
              class="btn-icon mt-1"
              style="margin-right: 1rem"
              @click="btnModal('withdraw')"
            >
              총판 출금신청
            </b-button>
            <b-form-checkbox
              v-model="isExpandCollapse"
              checked="false"
              class="custom-control-primary mt-1"
              style="margin-right: 2rem"
              name="check-button"
              switch
              @change="toggleExpandCollapse"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CornerRightDownIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="CropIcon" />
              </span>
            </b-form-checkbox>
            <b-modal
              ref="ModalBranchCashOut"
              no-close-on-backdrop
              hide-footer
              centered
              size="sm"
            >
              <!-- Modal Header -->
              <template #modal-header>
                <h5 class="modal-title">
                  총판출금신청
                </h5>
                <div class="modal-actions">
                  <feather-icon
                    icon="XIcon"
                    class="ml-1 cursor-pointer"
                    @click="$refs.ModalBranchCashOut.hide()"
                  />
                </div>
              </template>
              <b-form @submit.prevent>
                <b-row>
                  <b-col>
                    <b-form-group
                      class="font-small-3"
                      label="총판명"
                      label-for="main-notice"
                      label-cols-md="4"
                    >
                      <div
                        class="font-small-3"
                        size="sm"
                        style="color:rgb(229,166,48); margin-top: 6px"
                      >
                        <!--                        {{ cashOutBranchName }}-->
                        {{ userData.nickname }}
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="font-small-3"
                      label="정산 적용일자"
                      label-for="main-notice"
                      label-cols-md="4"
                    >
                      <div
                        class="font-small-3"
                        size="sm"
                        style="color:rgb(229,166,48); margin-top: 6px"
                      >
                        {{ cashOutBranchDate }}
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="font-small-3"
                      label="출금 요청중인 금액"
                      label-for="main-notice"
                      label-cols-md="4"
                    >
                      <div
                        class="font-small-3"
                        size="sm"
                        style="color:rgb(229,166,48); margin-top: 6px"
                      >
                        {{ formatNumberWithCommas(cashOutBranchRequested) }}
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="font-small-3"
                      label="출금 가능한 금액"
                      label-for="main-notice"
                      label-cols-md="4"
                    >
                      <div
                        class="font-small-3"
                        size="sm"
                        style="color:rgb(229,166,48); margin-top: 6px"
                      >
                        {{ formatNumberWithCommas(cashOutBranchAvailable) }}
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="font-small-3"
                      label="출금 요청할 금액"
                      label-for="main-notice"
                      label-cols-md="4"
                    >
                      <b-form-input
                        id="main-notice"
                        v-model="cashOutBranchAmount"
                        class="font-small-3 text-info"
                        size="sm"
                        placeholder="출금요청할 금액을 입력하세요"
                        style="color:rgb(229,166,48)"
                        type="number"
                        :class="{ 'is-valid': cashOutBranchAmountValidateState }"
                      />
                      <b-form-invalid-feedback :state="cashOutBranchAmountValidateState">
                        {{ cashOutBranchAmountValidateMessage }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group
                      class="font-small-3"
                      label="출금 적용 후 금액"
                      label-for="main-notice"
                      label-cols-md="4"
                    >
                      <div
                        class="font-small-3"
                        size="sm"
                        style="color:rgb(229,166,48); margin-top: 6px"
                      >
                        {{ formatNumberWithCommas((cashOutBranchAvailable) - cashOutBranchAmount) || 0 }}
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="font-small-3"
                      label="요청 내용"
                      label-for="main-notice"
                      label-cols-md="4"
                    >
                      <b-form-textarea
                        id="main-notice"
                        v-model="cashOutBranchComment"
                        class="font-small-3 text-info"
                        size="sm"
                        placeholder="특이사항이 있을경우 입력하세요"
                        rows="2"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="py-1">
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="float-right"
                      :disabled="!cashOutBranchAmountValidateState"
                      @click="btnConfirm('BranchCashOut')"
                    >
                      출금신청
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      class="float-right mr-1"
                      @click="btnModal('close')"
                    >
                      취소
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-modal>
          </div>
        </div>
        <b-row class="m-1">
          <b-col>
            <b-table
              ref="branchDailyTable"
              responsive
              small
              show-empty
              empty-text="조회내역이 없습니다."
              thead-class="text-center"
              tbody-class="text-center"
              sort-by="baseDate"
              :sort-desc="false"
              :items="branchDailyItems"
              :fields="branchDailyFields"
              :tbody-transition-props="transProps"
              :tbody-tr-attr="rowStyle"
              class="main-table"
              @row-clicked="handleRowClick"
            >
              <template #thead-top>
                <b-tr>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 7rem;"
                  >
                    일자
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 10rem;"
                  >
                    총판명
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small;"
                  >
                    회원
                  </b-th>
                  <b-th
                    colspan="2"
                    class="align-middle"
                    style="font-size: small; "
                  >
                    현재머니
                  </b-th>
                  <b-th
                    colspan="2"
                    class="text-center align-middle"
                  >
                    입출금
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem; background-color: #21315a;"
                  >
                    충-환<br>
                    <span style="font-size: xx-small;">(C=A-B)</span>
                  </b-th>
                  <b-th
                    colspan="3"
                    class="text-center align-middle"
                  >
                    루징(D)
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="text-center align-middle"
                    style="background-color: #21315a;"
                  >
                    루징금<br>합계<br>
                    <span style="font-size: xx-small;"> (D=S+T)</span>
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem;"
                  >
                    총판<br> 누적 루징금<br>
                    <span style="font-size: xx-small;"> (E=D+D') </span>
                  </b-th>
                  <b-th
                    v-if="false"
                    colspan="9"
                    class="text-center align-middle"
                  >
                    롤링
                  </b-th>
                  <b-th
                    v-if="false"
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem; background-color: #21315a;"
                  >
                    롤링금 합계<br>
                    <span style="font-size: xx-small;">(F=O+P+Q)</span>
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem;"
                  >
                    총판<br>출금액<br>
                    <span style="font-size: xx-small;"> (G) </span>
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem;"
                  >
                    총판<br>출금가능<br>
                    <span style="font-size: xx-small;"> (H=E-G) </span>
                  </b-th>
                </b-tr>
                <b-tr>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    캐쉬
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    포인트
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    충전<br>
                    <span style="font-size: xx-small;">(A)</span>
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    환전<br>
                    <span style="font-size: xx-small;">(B)</span>
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    요율%
                    <span style="font-size: xx-small;">(R)</span>
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    자신
                    <span style="font-size: xx-small;">(S)</span>
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    하위
                    <span style="font-size: xx-small;">(T)</span>
                  </b-th>
                  <b-th
                    v-if="false"
                    colspan="3"
                    class="text-center align-middle"
                  >
                    스포츠
                  </b-th>
                  <b-th
                    v-if="false"
                    colspan="3"
                    class="text-center align-middle"
                  >
                    카지노
                  </b-th>
                  <b-th
                    v-if="false"
                    colspan="3"
                    class="text-center align-middle"
                  >
                    슬롯
                  </b-th>

                </b-tr>
                <b-tr>
                  <b-th
                    v-if="false"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    배팅금
                    <span style="font-size: xx-small;">(I)</span>
                  </b-th>
                  <b-th
                    v-if="false"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    요율%
                    <span style="font-size: xx-small;">(L)</span>
                  </b-th>
                  <b-th
                    v-if="false"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    롤링금
                    <span style="font-size: xx-small;">(O=I*L)</span>
                  </b-th>

                  <b-th
                    v-if="false"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    배팅금
                    <span style="font-size: xx-small;">(J)</span>
                  </b-th>
                  <b-th
                    v-if="false"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    요율%
                    <span style="font-size: xx-small;">(M)</span>
                  </b-th>
                  <b-th
                    v-if="false"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    롤링금
                    <span style="font-size: xx-small;">(P=J*M)</span>
                  </b-th>
                  <b-th
                    v-if="false"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    배팅금
                    <span style="font-size: xx-small;">(K)</span>
                  </b-th>
                  <b-th
                    v-if="false"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    요율%
                    <span style="font-size: xx-small;">(N)</span>
                  </b-th>
                  <b-th
                    v-if="false"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    롤링금
                    <span style="font-size: xx-small;">(Q=K*N)</span>
                  </b-th>
                </b-tr>
              </template>
              <template #cell(branch)="data">
                <div
                  class="d-flex align-items-center"
                  :style="{ paddingLeft: `${(data.item.level || 0) * 20}px` }"
                >
                  <!-- 확장/축소 아이콘 -->
                  <div
                    class="me-2"
                    style="width: 20px; text-align: center;"
                  >
                    <feather-icon
                      v-if="Array.isArray(data.item.children) && data.item.children.length > 0"
                      :icon="data.item.isExpanded ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                      size="14"
                    />
                  </div>

                  <!-- 브랜치 이름 -->
                  <div
                    style="min-width: 7rem;"
                  >
                    <template>
                      <div
                        class="d-flex align-items-center "
                      >
                        <b-avatar
                          class="cursor-pointer"
                          style="margin-right: 5px; "
                          size="20"
                          :src="data.item.role"
                          :variant="`${resolveUserRoleVariant(data.item.role)}`"
                          @click.stop="handleUserDetail(data.item)"
                        />
                        <!-- :text="avatarText(data.item.role)" -->
                        <span
                          class="text-success font-weight-bold text-nowrap cursor-pointer"
                          style="text-align: left !important; text-decoration: underline; text-underline-offset: 5px;"
                          @click.stop="handleUserDetail(data.item)"
                        >
                          {{ data.item.nickname }}
                        </span>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
              <template #cell(user)="data">
                <div class="d-flex align-items-start justify-content-between">
                  <div
                    class="d-flex flex-column align-items-center"
                    style="border-right: 1px solid #3b4253; padding-right: 8px;"
                  >
                    <div class="d-flex align-items-center">
                      <b-avatar
                        size="14"
                        src="branch"
                        :variant="resolveUserRoleVariant('branch')"
                        class=""
                      />
                      <span
                        style="display: inline-block; text-align: right; margin-left: 3px;"
                      >
                        {{ data.item.lowerRoleCount ? (JSON.parse(data.item.lowerRoleCount).branch || 0) : 0 }}
                      </span>
                    </div>
                    <div class="d-flex align-items-center">
                      <b-avatar
                        size="14"
                        src="user"
                        :variant="resolveUserRoleVariant('user')"
                        class=""
                      />
                      <span
                        style="display: inline-block;  text-align: right; margin-left: 3px;"
                      >
                        {{ data.item.lowerRoleCount ? (JSON.parse(data.item.lowerRoleCount).user || 0) : 0 }}
                      </span>
                    </div>
                  </div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    size="sm"
                    style="align-self: center; "
                    @click="handleShowUsers(data.item)"
                  >
                    <feather-icon
                      icon="UsersIcon"
                      size="12"
                    />
                  </b-button>
                </div>
              </template>
              <template #cell(cash)="data">
                <div
                  v-b-tooltip.hover.bottom.html="getTooltipContent(data.item.cash, data.item.lowerCash)"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumCash).toLocaleString() }}
                </div>
              </template>
              <template #cell(point)="data">
                <div
                  v-b-tooltip.hover.bottom.html="getTooltipContent(data.item.point, data.item.lowerPoint)"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumPoint).toLocaleString() }}
                </div>
              </template>
              <template #cell(deposit)="data">
                <div
                  v-b-tooltip.hover.bottom.html="getTooltipContent(data.item.deposit, data.item.lowerDeposit)"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumDeposit).toLocaleString() }}
                </div>
              </template>
              <template #cell(exchange)="data">
                <div
                  v-b-tooltip.hover.bottom.html="getTooltipContent(data.item.exchange, data.item.lowerExchange)"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumExchange).toLocaleString() }}
                </div>
              </template>
              <template #cell(balance)="data">
                <div
                  v-b-tooltip.hover.bottom.html="getTotalBalance(data).tooltip"
                  class="text-right"
                  style="font-size: small; min-width: 6rem; "
                >
                  {{ getTotalBalance(data).total.toLocaleString() }}
                </div>
              </template>
              <!-- 자신루징금 -->
              <template #cell(losing)="data">
                <div
                  v-if="Number(data.item.losing)"
                  :style="{
                    fontSize: 'small',
                    minWidth: '6rem',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    textUnderlineOffset: '5px'
                  }"
                  @mouseenter="event => event.target.style.textDecoration = 'underline'"
                  @mouseleave="event => event.target.style.textDecoration = 'none'"
                  @click.stop="fetchDailyBalanceLosing(data.item, 1)"
                >
                  {{ Number(data.item.losing).toLocaleString() }}
                </div>
                <div
                  v-else
                >
                  {{ Number(data.item.losing).toLocaleString() }}
                </div>
              </template>
              <!-- 하위루징금 -->
              <template #cell(accLowerLosing)="data">
                <div
                  v-if="Number(data.item.accLowerLosing)"
                  :style="{
                    fontSize: 'small',
                    minWidth: '6rem',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    textUnderlineOffset: '5px'
                  }"
                  @mouseenter="event => event.target.style.textDecoration = 'underline'"
                  @mouseleave="event => event.target.style.textDecoration = 'none'"
                  @click.stop="fetchDailyBalanceLosing(data.item, 2)"
                >
                  {{ Number(data.item.accLowerLosing).toLocaleString() }}
                </div>
                <div
                  v-else
                >
                  {{ Number(data.item.accLowerLosing).toLocaleString() }}
                </div>
              </template>
              <!-- 루징금합계 -->
              <template #cell(sumLosing)="data">
                <div
                  v-if="Number(data.item.sumLosing)"
                  v-b-tooltip.hover.bottom.html="getTooltipContent(data.item.losing, data.item.accLowerLosing)"
                  class="text-right"
                  :style="{
                    fontSize: 'small',
                    minWidth: '6rem',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    textUnderlineOffset: '5px'
                  }"
                  @mouseenter="event => event.target.style.textDecoration = 'underline'"
                  @mouseleave="event => event.target.style.textDecoration = 'none'"
                  @click.stop="fetchDailyBalanceLosing(data.item, 3)"
                >
                  {{ Number(data.item.sumLosing).toLocaleString() }}
                </div>
                <div
                  v-else
                >
                  {{ Number(data.item.sumLosing).toLocaleString() }}
                </div>
              </template>
              <!-- 총판 누적 루징금 -->
              <template #cell(accLosing)="data">
                <div
                  v-b-tooltip.hover.bottom.html="getTooltipContent(data.item.preAccLosing, data.item.sumLosing, 1)"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.accLosing).toLocaleString() }}
                </div>
              </template>
              <template #cell(sportBettingAmount)="data">
                <div
                  v-b-tooltip.hover.bottom.html="getTooltipContent(data.item.sportBettingAmount, data.item.lowerSportBettingAmount)"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumSportBettingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(sportRollingRate)="data">
                <div class="text-center">
                  {{ data.item.sportRollingRate || '-' }}
                </div>
              </template>
              <template #cell(sportRollingAmount)="data">
                <div
                  v-b-tooltip.hover.bottom.html="getTooltipContent(data.item.sportRollingAmount, data.item.lowerSportRollingAmount)"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumSportRollingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(casinoBettingAmount)="data">
                <div
                  v-if="Number(data.item.sumCasinoBettingAmount)"
                  v-b-tooltip.hover.bottom.html="getTooltipContent(data.item.casinoBettingAmount, data.item.lowerCasinoBettingAmount)"
                  class="text-right"
                  :style="{
                    fontSize: 'small',
                    minWidth: '6rem',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    textUnderlineOffset: '5px'
                  }"
                  @mouseenter="event => event.target.style.textDecoration = 'underline'"
                  @mouseleave="event => event.target.style.textDecoration = 'none'"
                  @click.stop="fetchDailyBalanceRolling('casino', data.item)"
                >
                  {{ Number(data.item.sumCasinoBettingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(casinoRollingRate)="data">
                <div class="text-center">
                  {{ data.item.casinoRollingRate || '-' }}
                </div>
              </template>
              <template #cell(casinoRollingAmount)="data">
                <div
                  v-if="Number(data.item.sumCasinoRollingAmount)"
                  v-b-tooltip.hover.bottom.html="getTooltipContent(data.item.casinoRollingAmount, data.item.lowerCasinoRollingAmount)"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                  :style="{
                    fontSize: 'small',
                    minWidth: '6rem',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    textUnderlineOffset: '5px'
                  }"
                  @mouseenter="event => event.target.style.textDecoration = 'underline'"
                  @mouseleave="event => event.target.style.textDecoration = 'none'"
                  @click.stop="fetchDailyBalanceRolling('casino', data.item)"
                >
                  {{ Number(data.item.sumCasinoRollingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(slotBettingAmount)="data">
                <div
                  v-b-tooltip.hover.bottom.html="getTooltipContent(data.item.slotBettingAmount, data.item.lowerSlotBettingAmount)"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumSlotBettingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(slotRollingRate)="data">
                <div class="text-center">
                  {{ data.item.slotRollingRate || '-' }}
                </div>
              </template>
              <template #cell(slotRollingAmount)="data">
                <div
                  v-b-tooltip.hover.bottom.html="getTooltipContent(data.item.slotRollingAmount, data.item.lowerSlotRollingAmount)"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumSlotRollingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(rollingAmount)="data">
                <div>
                  {{ data.item.rollingAmount.toLocaleString() }}
                </div>
                <div
                  v-if="false"
                  class="text-right"
                  :style="{
                    fontSize: 'small',
                    minWidth: '6rem',
                    textDecoration: data.item.rollingAmount > 0 ? 'underline' : 'none',
                    textUnderlineOffset: data.item.rollingAmount > 0 ? '5px' : 'initial'
                  }"
                  @click="fncFetchBalanceRolling(data.item)"
                >
                  {{ data.item.rollingAmount.toLocaleString() }}
                </div>
              </template>
              <template #cell(withdrawableAmount)="data">
                <div>
                  {{ (data.item.withdrawableAmount- data.item.withdrawnAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(type)="data">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="btn-icon"
                  size="sm"
                  :variant="data.item.type === 'ok' ? 'secondary' : 'primary'"
                  :disabled="data.item.type === 'ok'"
                  @click="updateStatusCommission(data.item)"
                >
                  <feather-icon
                    :icon="data.item.type === 'ok' ? 'CheckIcon' : 'DollarSignIcon'"
                    size="14"
                  />
                </b-button>
              </template>
              <template
                v-if="branchDailyItems.length > 0"
                slot="bottom-row"
              >
                <td
                  colspan="5"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  Total
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumDeposit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumExchange')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumDeposit')-getTotal('sumExchange')).toLocaleString() }}
                </td>
                <td
                  style="background-color: #24233a; color:rgb(196,186,138)"
                />
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('losing')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('accLowerLosing')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumLosing')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('accLosing')).toLocaleString() }}
                </td>
                <td
                  v-if="false"
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumSportBettingAmount')).toLocaleString() }}
                </td>
                <td
                  v-if="false"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                />
                <td
                  v-if="false"
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumSportRollingAmount')).toLocaleString() }}
                </td>
                <td
                  v-if="false"
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumCasinoBettingAmount')).toLocaleString() }}
                </td>
                <td
                  v-if="false"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                />
                <td
                  v-if="false"
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumCasinoRollingAmount')).toLocaleString() }}
                </td>
                <td
                  v-if="false"
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumSlotBettingAmount')).toLocaleString() }}
                </td>
                <td
                  v-if="false"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                />
                <td
                  v-if="false"
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumSlotRollingAmount')).toLocaleString() }}
                </td>
                <td
                  v-if="false"
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('rollingAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('withdrawnAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Math.abs(Number(getTotal('withdrawableAmount')-getTotal('withdrawnAmount'))).toLocaleString() }}
                </td>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>

      <branch-cash-out-history
        ref="BranchCashOutHistory"
      />
      <branch-cash-out-request
        ref="BranchCashOutRequest"
      />
      <branch-balance-rolling
        ref="BranchBalanceRolling"
        :fetch-balance-rolling="fetchBalanceRolling"
      />

      <daily-balance-losing
        ref="DailyBalanceLosing"
        :losing="losing"
      />
      <daily-balance-rolling
        ref="DailyBalanceRolling"
        :rolling="rolling"
      />
    </b-card>
    <!-- <div> {{ branchDailyItems }} </div> -->
    <hr>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BTable,
  BTr,
  BTh,
  BButton,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BBadge,
  BFormDatepicker,
  BCardText,
  BFormTextarea,
  VBTooltip,
  BMedia,
  BAvatar,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'

import router from '@/router'

import { createNamespacedHelpers } from 'vuex'
import {
  FETCH_BRANCH_SELECT_OPTIONS, FETCH_BRANCH_WITHDRAWABLE_DAY, FETCH_BALANCE_BRANCH_DAILY_PROFIT, FETCH_DAILY_BALANCE_LOSING, FETCH_DAILY_BALANCE_ROLLING, FETCH_BALANCE_ROLLING, FETCH_BRANCH_ORGANIZATION_CHART, FETCH_BRANCH_ORGANIZATION_CHART_SUB,
} from '@/store/branch/action'
import { CREATE_BRANCH_CASHOUT_REQUEST } from '@/store/branch/mutation'
import { FETCH_REQUEST_ALARMS_BRANCH_CASHOUT } from '@/store/notice/action'
import { UPSERT_REQUEST_ALARMS } from '@/store/settings/mutation'

import BranchCashOutHistory from '@/views/branch/BranchCashOutHistory.vue'
import BranchCashOutRequest from '@/views/branch/BranchCashOutRequest.vue'
import BranchBalanceRolling from '@/views/branch/BranchCalculateDailyBalanceRolling.vue'
import DailyBalanceLosing from '@/views/branch/DailyBalanceLosing.vue'
import DailyBalanceRolling from '@/views/branch/DailyBalanceRolling.vue'

import * as moment from 'moment-timezone'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
const fmt2 = 'YYYYMMDD'
const fmt3 = 'YYYY-MM-DD'

const branchStore = createNamespacedHelpers('branchStore')
const noticeStore = createNamespacedHelpers('noticeStore')
const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  name: 'BranchCalculateDaily',

  components: {
    BCard,
    BCardBody,
    BTable,
    BTr,
    BTh,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BBadge,
    BFormDatepicker,
    vSelect,
    BCardText,
    BMedia,
    BAvatar,
    BFormTextarea,

    BranchCashOutHistory,
    BranchCashOutRequest,
    BranchBalanceRolling,

    DailyBalanceLosing,
    DailyBalanceRolling,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    return {
      currentURL: window.location.href,
      componentName: this.$options.name,
      avatarText,

      // userIp: localStorage.getItem('ip'),
      branchSelected: null,

      searchFilter: null,
      filteredBranchList: [],

      isBranchWithrawable: false,
      msgBranchWithrawable: '',
      // cashOutBranchName: JSON.parse(localStorage.getItem('userData')).nickname,
      cashOutBranchDate: moment(new Date()).subtract(1, 'days').format(fmt3),
      // cashOutBranchRequested: 0,
      // cashOutBranchAvailable: 0,
      cashOutBranchAmount: '',
      cashOutBranchComment: '',

      cashOutBranchAmountValidateState: null,
      cashOutBranchAmountValidateMessage: '',

      branchDailyItems: [],
      branchDailyFields: [
        { key: 'baseDate', thClass: 'd-none ', formatter: 'getDateWithDash' },
        { key: 'branch', thClass: 'd-none ', tdClass: 'text-left' },
        { key: 'user', thClass: 'd-none', tdClass: 'text-center' },
        {
          key: 'cash', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas',
        }, // 현재머니(캐쉬)
        {
          key: 'point', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas',
        }, // 현재머니(포인트)
        {
          key: 'deposit', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas',
        }, // 입출금(출금)
        {
          key: 'exchange', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas',
        }, // 입출금(환전)
        {
          key: 'balance', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas',
        }, // 충환
        { key: 'rate', thClass: 'd-none' },
        {
          key: 'losing', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas',
        },
        {
          key: 'accLowerLosing', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas',
        },
        {
          key: 'sumLosing', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas',
        }, // 루징금합계
        {
          key: 'accLosing', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas',
        }, // 총판 누적 루징금
        // { key: 'sportBettingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        // { key: 'sportRollingRate', thClass: 'd-none', tdClass: 'text-right' },
        // { key: 'sportRollingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        // { key: 'casinoBettingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        // { key: 'casinoRollingRate', thClass: 'd-none', tdClass: 'text-right' },
        // { key: 'casinoRollingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        // { key: 'slotBettingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        // { key: 'slotRollingRate', thClass: 'd-none', tdClass: 'text-right' },
        // { key: 'slotRollingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        // { key: 'rollingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        {
          key: 'withdrawnAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas',
        },
        {
          key: 'withdrawableAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas',
        },
      ],
      periodFrom: '',
      periodTo: '',

      transProps: {
        name: 'fade',
        mode: 'in-out',
      },
      isExpandCollapse: false,

      losing: null, // losing 상세 구분값
      rolling: null, // rolling 상세 구분값
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.init()
      },
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    userIp() {
      return this.$store.getters['rootStore/ip']
    },
    ...branchStore.mapGetters({
      fetchBranchSelectOptions: 'fetchBranchSelectOptions',
      fetchBranchWithdrawableDay: 'fetchBranchWithdrawableDay',
      fetchBalanceBranchDailyProfit: 'fetchBalanceBranchDailyProfit',
      fetchBalanceRolling: 'fetchBalanceRolling',
      fetchBranchOrganizationChart: 'fetchBranchOrganizationChart',
      fetchBranchOrganizationChartSub: 'fetchBranchOrganizationChartSub',
    }),
    ...noticeStore.mapGetters({
      fetchRequestAlarmsBranchCashout: 'fetchRequestAlarmsBranchCashout',
    }),

    // 일자별 계층구조로 변환
    filteredBalanceTreeByBaseDate() {
      // 1. 일자별로 그룹화
      const groupByDate = data => data.reduce((acc, item) => {
        if (!acc[item.baseDate]) {
          acc[item.baseDate] = []
        }
        acc[item.baseDate].push(item)
        return acc
      }, {})

      // 2. 계층구조 생성
      const buildHierarchy = data => {
        const map = new Map()
        const node = []

        // 모든 항목을 Map에 저장하고 초기 level을 설정
        data.forEach(item => {
          map.set(item.nickname, { ...item, level: 0 })
        })

        // 계층구조 생성
        data.forEach(item => {
          if (item.recommendNickname && map.has(item.recommendNickname)) {
            const parent = map.get(item.recommendNickname)

            // 부모에 children 속성이 없으면 추가
            if (!parent.children) {
              parent.children = []
            }

            const child = map.get(item.nickname)
            child.level = parent.level + 1 // 자식의 level을 1증가

            parent.children.push(child) // 부모 노드의 children 배열에 추가
          } else {
            node.push(map.get(item.nickname)) // 최상위 노드
          }
        })

        return node
      }

      // 3.일자별 그룹화된 데이터를 계층 구조로 변환하는 함수
      const transformDataByDate = data => {
        const groupedByDate = groupByDate(data)

        return Object.keys(groupedByDate).reduce((acc, date) => {
          acc.push(...buildHierarchy(groupedByDate[date]))
          return acc
        }, [])
      }

      const resultData = transformDataByDate(this.fetchBalanceBranchDailyProfit)
      // console.log('filteredBalanceTreeByBaseDate :\n ', resultData)

      return resultData
    },

    branchSelectOptions() {
      const resultData = this.fetchBranchSelectOptions.map(option => ({
        value: option,
        text: option.status === 'active'
          ? `[${option.role}] ${option.nickname}`
          : `[${option.role}] ${option.nickname} (${option.status})`,
        selected: option.userid === this.userData.userid,
      }))
      this.branchSelected = this.fetchBranchSelectOptions.find(option => option.userid === this.userData.userid)
      return resultData
    },
    // 총판 출금 신청금액
    cashOutBranchRequested() {
      return this.fetchRequestAlarmsBranchCashout.reduce((sum, item) => ((item.requestNickname === this.branchSelected.branch && item.confirmed === 'REQUEST') ? sum + item.requestCash : sum), 0)
    },
    // 총판 출금 가능금액
    cashOutBranchAvailable() {
      const baseDate = moment(new Date()).subtract(1, 'days').format(fmt2) // 기준일자
      // console.log(JSON.stringify(this.branchDailyItems))
      // console.log(baseDate, this.branchSelected.branch, this.branchDailyItems.find(item => item.branch === this.branchSelected.branch && item.baseDate === baseDate))
      // const branchCashBalance = this.branchDailyItems.find(item => item.branch === this.branchSelected.branch && item.baseDate === baseDate)?.branchCashBalance 임시

      const branchCashBalance = this.branchDailyItems.find(item => item.branch === this.branchSelected.branch && item.baseDate === baseDate)?.totalProfitRunning
      // console.log('총판 출금 가능금액', branchCashBalance)
      // console.log('총판 출금 신청금액', this.cashOutBranchRequested)
      return branchCashBalance - this.cashOutBranchRequested || 0
    },
  },
  watch: {
    branchSelected: {
      handler() {
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
    periodFrom: {
      handler() {
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
    periodTo: {
      handler() {
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
    searchFilter() {
      // console.log(this.fetchRequestAlarmsBranchCashout)

      // if (this.searchFilter) {
      //   const data = this.fetchBalanceBranchDailyProfit.filter(branch => Object.values(branch).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      //   this.branchDailyItems = data
      // }
      // if (this.searchFilter === '') {
      //   this.branchDailyItems = this.filteredBalanceTreeByBaseDate
      // }
    },
    cashOutBranchAmount(newVal, oldVal) {
      // console.log('newVal :', newVal)
      // console.log('oldVal :', oldVal)
      // if (newVal > this.cashOutBranchAvailable) {
      //   this.$nextTick(() => {
      //     this.cashOutBranchAmount = oldVal // 이전 값으로 되돌리기
      //   })
      // }
      this.fncCashOutBranchAmountValidate(newVal, oldVal)
    },
  },
  created() {
    // console.log(this.componentName, 'created()')
    this.init()
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
  },
  methods: {
    ...branchStore.mapActions({
      $fetchBranchSelectOptions: FETCH_BRANCH_SELECT_OPTIONS,
      $fetchBranchWithdrawableDay: FETCH_BRANCH_WITHDRAWABLE_DAY,
      $fetchBalanceBranchDailyProfit: FETCH_BALANCE_BRANCH_DAILY_PROFIT,
      $fetchBalanceRolling: FETCH_BALANCE_ROLLING,
      $fetchDailyBalanceLosing: FETCH_DAILY_BALANCE_LOSING,
      $fetchDailyBalanceRolling: FETCH_DAILY_BALANCE_ROLLING,
      $createBranchCashoutRequest: CREATE_BRANCH_CASHOUT_REQUEST,
      $fetchBranchOrganizationChart: FETCH_BRANCH_ORGANIZATION_CHART,
      $fetchBranchOrganizationChartSub: FETCH_BRANCH_ORGANIZATION_CHART_SUB,
    }),
    ...noticeStore.mapActions({
      $fetchRequestAlarmsBranchCashout: FETCH_REQUEST_ALARMS_BRANCH_CASHOUT,
    }),
    ...settingsStore.mapActions({
      $upsertRequestAlarms: UPSERT_REQUEST_ALARMS,
    }),
    async init() {
      this.periodFrom = moment().startOf('day').format(fmt1) // 이달의 첫 번째 날
      this.periodTo = moment().endOf('day').format(fmt1) // 오늘 날짜
      this.periodFrom = '2025-02-11' // 이달의 첫 번째 날
      this.periodTo = '2025-02-11' // 오늘 날짜

      await this.$fetchBranchSelectOptions({
        site: this.siteSelected,
        userid: this.userData.userid,
      })
      await this.fetchData()
      this.isActiveBranchWithrawable() /* 출금신청 버튼 활성화 로직 */
    },
    searchData() {
      this.fetchData()
    },
    async fetchData() {
      if (!this.branchSelected) return

      await this.$fetchBranchWithdrawableDay({
        site: this.siteSelected,
        userid: this.branchSelected.userid,
      })
      await this.$fetchBalanceBranchDailyProfit({
        site: this.siteSelected,
        userid: this.branchSelected.userid,
        fromAt: this.periodFrom,
        toAt: this.periodTo,
      })
      this.branchDailyItems = this.filteredBalanceTreeByBaseDate
      this.expandAllRows()
      // this.expandAllRows(1) // 1단계만 펼친 상태로 설정
    },
    handleUserDetail(item) {
      // 기존 창에서 열기
      // localStorage.setItem('userView', JSON.stringify({ userid: item.userid, site: item.site, nickname: item.nickname }))
      // router.push({ name: 'apps-user-view' })

      // 새로운 창에서 열기
      // URL 쿼리 파라미터로 데이터를 전달
      const queryParams = {
        userid: item.userid,
        site: item.site,
        nickname: item.nickname,
      }
      const url = router.resolve({
        name: 'apps-user-view',
        query: queryParams,
      }).href
      // 새로 고유한 이름을 가진 팝업 창을 열기 위해 현재 시간 사용
      const popupName = `userViewPopup_${new Date().getTime()}`
      const popupOptions = 'width=1800,height=1250,scrollbars=yes,resizable=yes' // 원하는 옵션 설정
      window.open(url, popupName, popupOptions) // 매번 다른 이름으로 팝업 창 열기
    },
    handleShowUsers(item) {
      console.log(item.userid)
      // 새로운 창에서 열기
      // URL 쿼리 파라미터로 데이터를 전달
      const queryParams = {
        userid: item.userid,
        fromAt: moment(item.baseDate, 'YYYYMMDD').format('YYYY-MM-DD'),
        toAt: moment(item.baseDate, 'YYYYMMDD').format('YYYY-MM-DD'),
      }
      const url = this.$router.resolve({
        name: 'branch-users',
        query: queryParams,
      }).href

      // 새로 고유한 이름을 가진 팝업 창을 열기 위해 현재 시간 사용
      const popupName = `userViewPopup_${new Date().getTime()}`

      const screenWidth = window.innerWidth || document.documentElement.clientWidth || screen.width
      const screenHeight = window.innerHeight || document.documentElement.clientHeight || screen.height

      // 창 크기 계산 (너비 70%, 높이 60%)
      const popupWidth = Math.floor(screenWidth * 0.7)
      const popupHeight = Math.floor(screenHeight * 0.6)

      // 창 위치 계산 (화면 중앙 정렬)
      const left = Math.floor((screenWidth - popupWidth) / 2 + window.screenX)
      const top = Math.floor((screenHeight - popupHeight) / 2 + window.screenY)

      // 새 창 옵션 설정
      const popupOptions = `width=${popupWidth},height=${popupHeight},left=${left},top=${top},scrollbars=yes,resizable=yes`

      window.open(url, popupName, popupOptions)
    },
    async fetchDailyBalanceLosing(item, type) {
      await this.$fetchDailyBalanceLosing({
        site: item.site,
        userid: item.userid,
        baseDate: item.baseDate,
      })
      this.losing = {
        site: item.site,
        userid: item.userid,
        fromAt: this.periodFrom,
        toAt: this.periodTo,
        type,
      }
      this.btnModal('losing')
    },
    async fetchDailyBalanceRolling(type, item) {
      await this.$fetchDailyBalanceRolling({
        site: item.site,
        userid: item.userid,
        fromAt: this.periodFrom,
        toAt: this.periodTo,
        type,
      })
      this.rolling = {
        site: item.site,
        userid: item.userid,
        fromAt: this.periodFrom,
        toAt: this.periodTo,
        type,
      }
      this.btnModal('rolling')
    },
    async fncFetchBalanceRolling(item) {
      await this.$fetchBalanceRolling({
        site: item.site,
        userid: item.userid,
        baseDate: item.baseDate,
      })
      this.btnModal('balanceRolling')
    },
    toggleExpandCollapse() {
      if (this.isExpandCollapse) {
        this.expandAllRows()
      } else {
        this.collapseAllRows()
      }
    },
    expandRow(index, item) {
      if (Array.isArray(item.children) && item.children.length > 0) {
        const childrenWithLevel = item.children.map(child => ({
          ...child,
          // level: (item.level || 0) + 1,
          // isExpanded: false,
        }))
        this.branchDailyItems.splice(index + 1, 0, ...childrenWithLevel)
      }
    },
    collapseRow(index, item) {
      const startLevel = item.level || 0
      let endIndex = index + 1

      while (endIndex < this.branchDailyItems.length && (this.branchDailyItems[endIndex].level || 0) > startLevel) {
        endIndex += 1
      }

      this.branchDailyItems.splice(index + 1, endIndex - index - 1)
    },
    // 전체 펼치기
    expandAllRows(maxLevel = Infinity) {
      const recursivelyExpand = (items, currentLevel = 0) => items.flatMap(item => {
        if (!item) {
          return null
        }
        if (item.isExpanded || !Array.isArray(item.children) || currentLevel >= maxLevel) {
          return item // 현재 항목을 그대로 반환
        }

        // 현재 항목을 펼치기
        const expandedItem = { ...item, isExpanded: true }

        // 자식 항목이 있는 경우 재귀적으로 처리
        return [
          expandedItem,
          ...recursivelyExpand(item.children ?? [], currentLevel + 1), // `?? []`로 안전하게 처리
        ]
      })

      // 최상위 항목부터 시작
      this.branchDailyItems = recursivelyExpand(this.branchDailyItems)
    },
    // 전체 접기
    collapseAllRows() {
      const updatedItems = []
      this.branchDailyItems.forEach(item => {
        if (item.level === 0) {
          updatedItems.push({ ...item, isExpanded: false })
        }
      })
      this.branchDailyItems = updatedItems
    },
    // 펼치기/접기
    handleRowClick(item, index) {
      if (item.children && item.children.length > 0) {
        if (!item.isExpanded) {
          this.expandRow(index, item)
        } else {
          this.collapseRow(index, item)
        }
        this.$set(this.branchDailyItems, index, {
          ...item,
          isExpanded: !item.isExpanded,
        })
      }
    },

    getTotalBalance(data) {
      const deposit = Number(data.item.deposit) || 0
      const lowerDeposit = Number(data.item.lowerDeposit) || 0
      const exchange = Number(data.item.exchange) || 0
      const lowerExchange = Number(data.item.lowerExchange) || 0

      // 계산된 잔액
      const total = (deposit + lowerDeposit) - (exchange + lowerExchange)

      let tooltip = ''
      if (deposit > 0 || lowerDeposit > 0 || exchange > 0 || lowerExchange > 0) {
        // tooltip = `(${deposit.toLocaleString()} + ${lowerDeposit.toLocaleString()}) - (${exchange.toLocaleString()} + ${lowerExchange.toLocaleString()}) = ${total.toLocaleString()}`
        tooltip = `
          <div style="display: table">
            <div style="display: table-row;">
              <div style="display: table-cell; text-align: right; padding-right: 5px;">충전 (자신)</div>
              <div style="display: table-cell; text-align: center; padding-right: 5px;">:</div>
              <div style="display: table-cell; text-align: right;">${Number(deposit).toLocaleString()}</div>
            </div>
            <div style="display: table-row;">
              <div style="display: table-cell; text-align: right; padding-right: 5px;">충전 (하위)</div>
              <div style="display: table-cell; text-align: center; padding-right: 5px;">:</div>
              <div style="display: table-cell; text-align: right;">${Number(lowerDeposit).toLocaleString()}</div>
            </div>
            <div style="display: table-row;">
              <div style="display: table-cell; text-align: right; padding-right: 5px;">환전 (자신)</div>
              <div style="display: table-cell; text-align: center; padding-right: 5px;">:</div>
              <div style="display: table-cell; text-align: right;">${Number(exchange).toLocaleString()}</div>
            </div>
            <div style="display: table-row;">
              <div style="display: table-cell; text-align: right; padding-right: 5px;">환전 (하위)</div>
              <div style="display: table-cell; text-align: center; padding-right: 5px;">:</div>
              <div style="display: table-cell; text-align: right;">${Number(lowerExchange).toLocaleString()}</div>
            </div>
            <div style="display: table-row;">
              <div style="display: table-cell; text-align: right; padding-right: 5px;">충환</div>
              <div style="display: table-cell; text-align: center; padding-right: 5px;">:</div>
              <div style="display: table-cell; text-align: right;">${Number(total).toLocaleString()}</div>
            </div>
          </div>
        `
      }

      return {
        total,
        tooltip,
      }
    },
    getTooltipContent(upperValue, lowerValue, type = 0) {
      // 두 값이 모두 0이면 빈 문자열 반환 (툴팁 숨김)
      if (Number(upperValue) === 0 && Number(lowerValue) === 0) {
        return ''
      }

      return `
        <div style="display: table">
          <div style="display: table-row;">
            <div style="display: table-cell; text-align: right; padding-right: 5px;">${type === 1 ? '전일' : '자신'}</div>
            <div style="display: table-cell; text-align: center; padding-right: 5px;">:</div>
            <div style="display: table-cell; text-align: right;">${Number(upperValue).toLocaleString()}</div>
          </div>
          <div style="display: table-row;">
            <div style="display: table-cell; text-align: right; padding-right: 5px;">${type === 1 ? '기준일' : '하위'}</div>
            <div style="display: table-cell; text-align: center; padding-right: 5px;"">:</div>
            <div style="display: table-cell; text-align: right;">${Number(lowerValue).toLocaleString()}</div>
          </div>
        </div>
      `
    },

    async fncFetchBalanceDepositExchange(data) {
      // await this.$fetchBalanceDepositExchange({
      //   site: this.siteSelected,
      //   branch: data.item.branch,
      //   fromAt: data.item.baseDate,
      //   toAt: data.item.baseDate,
      // })
      // this.btnModal('balanceDepositExchange')
    },

    /* [formatter] */
    formatNumberWithCommas(value) {
      if (value == null || Number.isNaN(Number(value))) {
        return value
      }

      return parseInt(value, 10).toLocaleString() // 정수로 변환 후 천 단위 쉼표 추가
    },
    getDateWithDash(value) {
      if (value == null) {
        return ''
      }
      return moment(value, 'YYYYMMDDHHmmss').format(fmt3)
    },
    getTotal(field) {
      return this.branchDailyItems.reduce((acc, item) => acc + item[field], 0)
    },
    getLast(field) {
      return this.branchDailyItems.reduce((acc, item) => item[field], 0)
    },
    async btnConfirm(type) {
      if (type !== 'BranchCashOut') return false

      if (this.cashOutBranchAmountValidateState) {
        await this.fncShowSwal(
          'confirm',
          `총판 출금 금액( ${Number(this.cashOutBranchAmount).toLocaleString()}원)을 신청하시겠습니까?`,
        )
          .then(result => {
            if (result.isConfirmed) {
              try {
                this.$createBranchCashoutRequest({
                  site: this.siteSelected,
                  requestCash: Number(this.cashOutBranchAmount),
                  requsetMemo: this.cashOutBranchComment,
                })
              } catch {
                console.error()
              } finally {
                this.fncShowSwal('success', '출금 신청 완료되었습니다.')
                this.fetchData()
                this.btnModal('close')
              }
            }
          })
      }
    },
    btnModal(target) {
      switch (target) {
        case 'balanceRolling':
          this.$refs.BranchBalanceRolling.$bvModal.show('BranchCalculateDailyBalanceRolling')
          break
        case 'losing':
          this.$refs.DailyBalanceLosing.$bvModal.show('DailyBalanceLosing')
          break
        case 'rolling':
          this.$refs.DailyBalanceRolling.$bvModal.show('DailyBalanceRolling')
          break
        case 'withdraw':
          if (this.isBranchWithrawable) {
            // this.$refs.ModalBranchCashOut.show()
            this.$refs.BranchCashOutRequest.$bvModal.show('BranchCashOutRequest')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                // title: '출금가능시간이 아닙니다.',
                title: `${this.msgBranchWithrawable}`,
                icon: 'CheckIcon',
                // text: `${this.msgBranchWithrawable}`,
                variant: 'Prmiary',
              },
            },
            {
              position: 'top-center',
              // timeout: 8000,
            })
          }
          break
        case 'withdrawHistory':
          this.$refs.BranchCashOutHistory.$bvModal.show('BranchCashOutHistory')
          break
        default:
          break
      }
    },

    // baseDate 별로 Striped 되도록 설정
    rowStyle(item, type) {
      if (type === 'row') {
        const day = parseInt(item.baseDate.slice(-2), 10)
        return {
          style: day % 2 === 0
            ? 'background-color: #272a30;' // table-dark 스타일
            : 'background-color: #14161a;', // table-bright 스타일
        }
      }
    },

    /* 출금신청 버튼 활성화 로직 */
    isActiveBranchWithrawable() {
      // ✅ 금일 00:00 ~ 00:30 사이면 출금 불가
      const isValidBlockedTime = () => {
        const now = new Date() // 현재 시간

        const startOfTime = new Date(now) // 오늘 00:00:00 설정
        startOfTime.setHours(0, 0, 0, 0)

        const endOfTime = new Date(startOfTime) // 오늘 00:30:00 설정
        endOfTime.setMinutes(30)

        // ✅ 현재 시간이 출금 불가 시간대인지 확인
        const isBlocked = now >= startOfTime && now <= endOfTime

        return isBlocked
      }

      const isValidCronExpression = cron => {
        if (!cron) return false

        const now = new Date() // 현재 시간

        // 현재 시간, 날짜, 요일
        const [currentHour, currentMinute, today, currentDayOfWeek] = [now.getHours(), now.getMinutes(), now.getDate(), now.getDay()]
        const [minutes, hours, dayOfMonth, , dayOfWeek] = cron.split(' ')

        // 조건 체크
        const isTodayValid = dayOfMonth === '*' || today === Number(dayOfMonth)
        const isWeekdayValid = dayOfWeek === '*' || dayOfWeek.split(',').map(Number).includes(currentDayOfWeek)
        const isTimeValid = currentHour > Number(hours) || (currentHour === Number(hours) && currentMinute >= Number(minutes))

        // 출금 가능 여부
        const isBlocked = isTodayValid && isWeekdayValid && isTimeValid

        return isBlocked
      }

      const cronExpressionMessage = cron => {
        const [minutes, hours, dayOfMonth, , dayOfWeek] = cron.split(' ')
        const dayNames = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일']

        if (dayOfWeek !== '*') {
          return `매주 ${dayNames[dayOfWeek]} ${hours}시 ${minutes}분에 활성화됩니다.`
        } if (dayOfMonth === 'L') {
          return `매월 말일 ${hours}시 ${minutes}분에 활성화됩니다.`
        } if (dayOfMonth !== '*') {
          return `매월 ${dayOfMonth}일 ${hours}시 ${minutes}분에 활성화됩니다.`
        }
        return `매일 ${hours}시 ${minutes}분에 활성화됩니다.`
      }

      if (isValidBlockedTime()) {
        this.isBranchWithrawable = false // 명확하게 설정
        this.msgBranchWithrawable = '시스템 점검시간입니다' // 메시지 유지
        return
      }

      if (!['branch', 'user'].includes(this.userData.role)) {
        this.isBranchWithrawable = false // 명확하게 설정
        this.msgBranchWithrawable = '출금대상자가 아닙니다.' // 메시지 유지
        return
      }

      this.isBranchWithrawable = isValidCronExpression(this.fetchBranchWithdrawableDay)
      this.msgBranchWithrawable = cronExpressionMessage(this.fetchBranchWithdrawableDay)
    },

    // 출금 요청 금액 validate
    fncCashOutBranchAmountValidate(newVal, oldVal) {
      // 초기화
      this.cashOutBranchAmountValidateState = null
      this.cashOutBranchAmountValidateMessage = ''
      // 유효성 검사
      if (typeof newVal === 'number') {
        this.cashOutBranchAmountValidateState = false
        this.cashOutBranchAmountValidateMessage = '숫자를 입력바랍니다.'
        this.cashOutBranchAmount = null
      } else if (newVal < 10000) {
        this.cashOutBranchAmountValidateState = false
        this.cashOutBranchAmountValidateMessage = '출금 요청 최소금액은 10,000원 이상입니다.'
      } else if (newVal > this.cashOutBranchAvailable) {
        this.cashOutBranchAmountValidateState = false
        this.cashOutBranchAmountValidateMessage = '출금 요청 금액이 출금 가능 금액을 초과합니다.'
      } else {
        this.cashOutBranchAmountValidateState = true
        this.cashOutBranchAmountValidateMessage = ''
      }
    },
    resolveUserRoleVariant(role) {
      if (role === 'user') return 'secondary'
      if (role === 'branch') return 'warning'
      if (role === 'admin') return 'primary'
      if (role === 'godfather') return 'danger'
      return 'secondary'
    },

    // Swal
    fncShowSwal(type, title = null, text = null) {
      const config = {
        confirm: {
          title: '변경내용을 저장하시겠습니까?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            cancelButton: 'btn btn-dark',
            confirmButton: 'btn btn-primary mr-1',
          },
        },
        check: {
          title: '변경된 내용이 없습니다. \n 다시 확인바랍니다.',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
        cancel: {
          title: '변경된 내용이 저장되지 않습니다. 정말 나가시겠습니까?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            cancelButton: 'btn btn-dark',
            confirmButton: 'btn btn-primary mr-1',
          },
        },
        success: {
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
        error: {
          title: '저장 실패',
          icon: 'error',
          text: '시스템 관리자에게 문의바랍니다.',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
      }[type]

      // 추가적으로 전달된 title 있다면 해당 설정을 덮어씁니다.
      if (title) config.title = title

      // 추가적으로 전달된 text 있다면 해당 설정을 덮어씁니다.
      if (text === null) delete config.text

      // Swal을 실행합니다.
      return Swal.fire(config)
    },

    // beforeRouteLeave(to, from, next) {
    //   clearInterval(this.fncShowWdrw) // 페이지 이동 시 interval 정리
    //   next()
    // },
  },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.category-select {
  width: 80px;
}
/* .b-table {
    overflow-y: auto;
    max-height: 300px;
}
.b-table .table > thead {
  position: sticky !important;
  top: 0;
  z-index: 2;
} */

.b-table > thead > tr > th {
  border-right: solid 1.5px #3b4253 !important;
  border-bottom: solid 1.5px #3b4253 !important;
  min-width: 6rem;
  /* white-space: no-wrap !important; */
}
.b-table > thead > tr > th:last-child {
  border-right: none !important;
}
</style>
