<template>
  <div>
    <b-modal
      :id="componentName"
      no-close-on-backdrop
      hide-footer
      centered
      size="lg"
      @shown="btnModal('show')"
      @hidden="btnModal('close')"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          루징금 상세
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click.stop="btnModal('close')"
          />
        </div>
      </template>

      <!-- Modal Body -->
      <b-card no-body>
        <div>
          <b-table
            responsive
            small
            striped
            hover
            bordered
            thead-class="text-center"
            tbody-class="text-center"
            :items="filteredItems"
            :fields="fields"
            @row-clicked="toggleDetail"
          >
            <template #cell(bettingAmount)="data">
              <div class="d-flex nowrap-text">
                <span
                  class="text-right w-100"
                  style="color:rgb(196,186,138);"
                >
                  <!-- {{ data.item.bettingAmount !== null ? (data.item.bettingAmount).toLocaleString() : null }} -->
                  {{ data.item.bettingAmount }}
                </span>
              </div>
            </template>

            <template #cell(sportRollingRate)="data">
              {{ data.item.sportRollingRate.slice(-1)[0] }}
            </template>
            <template #cell(casinoRollingRate)="data">
              {{ data.item.casinoRollingRate.slice(-1)[0] }}
            </template>
            <template #cell(slotRollingRate)="data">
              {{ data.item.slotRollingRate.slice(-1)[0] }}
            </template>

            <template #cell(formula)="data">
              <div
                class="nowrap-text"
              >
                <span>
                  {{ data.item.formula }}
                </span>
              </div>
            </template>

            <template
              slot="bottom-row"
            >
              <td
                colspan="6"
                class="text-center"
                style="background-color: #24233a; color:rgb(196,186,138)"
              >
                Total
              </td>
              <td
                class="text-right"
                style="background-color: #24233a; color:rgb(196,186,138)"
              >
                {{ getTotal('bettingAmount') ? getTotal('bettingAmount').toLocaleString() : '' }}
              </td>
              <td
                class="text-right"
                style="background-color: #24233a; color:rgb(196,186,138)"
              />
              <td
                class="text-right"
                style="background-color: #24233a; color:rgb(196,186,138)"
              >
                {{ getTotal('rollingAmount').toLocaleString() }}
              </td>
              <td
                class="text-right"
                style="background-color: #24233a; color:rgb(196,186,138)"
              />
            </template>
            <template #row-details="row">
              <b-card>
                <b-table
                  responsive
                  striped
                  hover
                  bordered
                  small
                  :items="getRollingData(row.item.gameId)"
                  :fields="fields"
                >
                  <template #cell(bettingAmount)="data">
                    {{ data.item.bettingAmount ? data.item.bettingAmount.toLocaleString() : '' }}
                  </template>
                </b-table>
              </b-card>
            </template>
          </b-table>
        </div>

        <div>
          <!-- fetchDailyBalanceRolling: <br> {{ fetchDailyBalanceRolling.length }} -->
          <!-- rolling: <br> {{ filteredItems }} -->
          <!-- rolling: <br> {{ rollingData }} -->
        </div>
      </b-card>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal,
  BCard,
  BTable, BTr, BTh,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { createNamespacedHelpers } from 'vuex'

const branchStore = createNamespacedHelpers('branchStore')

export default {

  name: 'DailyBalanceRolling',
  components: {
    BModal,
    BCard,
    BTable,
    BTr,
    BTh,
  },
  directives: {
    Ripple,
  },
  props: {
    rolling: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,

      // TableFilter
      searchFilter: '',

      // _fields: {
      //   sport: [
      //     { key: 'userid', label: '사용자 ID' },
      //     { key: 'nickname', label: '닉네임' },
      //     { key: 'recommendNickname', label: '추천 닉네임' },
      //     { key: 'sportBettingAmount', label: '스포츠 배팅금' },
      //     { key: 'lowerSportBettingAmount', label: '하위 스포츠 배팅금' },
      //     { key: 'sumSportBettingAmount', label: '총 스포츠 배팅금' },
      //     { key: 'sportRollingRate', label: '스포츠 롤링 요율' },
      //     { key: 'sportRollingAmount', label: '스포츠 롤링금' },
      //     { key: 'lowerSportRollingAmount', label: '하위 스포츠 롤링금' },
      //     { key: 'sumSportRollingAmount', label: '총 스포츠 롤링금' },
      //   ],
      //   casino: [
      //     { key: 'userid', label: '사용자 ID' },
      //     { key: 'nickname', label: '닉네임' },
      //     { key: 'recommendNickname', label: '추천 닉네임' },
      //     { key: 'casinoBettingAmount', label: '카지노 배팅금' },
      //     { key: 'lowerCasinoBettingAmount', label: '하위 카지노 배팅금' },
      //     { key: 'sumCasinoBettingAmount', label: '총 카지노 배팅금' },
      //     { key: 'casinoRollingRate', label: '카지노 롤링 요율' },
      //     { key: 'casinoRollingAmount', label: '카지노 롤링금' },
      //     { key: 'lowerCasinoRollingAmount', label: '하위 카지노 롤링금' },
      //     { key: 'sumCasinoRollingAmount', label: '총 카지노 롤링금' },
      //   ],
      //   slot: [
      //     { key: 'userid', label: '사용자 ID' },
      //     { key: 'nickname', label: '닉네임' },
      //     { key: 'recommendNickname', label: '추천 닉네임' },
      //     { key: 'slotBettingAmount', label: '슬롯 배팅금' },
      //     { key: 'lowerSlotBettingAmount', label: '하위 슬롯 배팅금' },
      //     { key: 'sumSlotBettingAmount', label: '총 슬롯 배팅금' },
      //     { key: 'slotRollingRate', label: '슬롯 롤링 요율' },
      //     { key: 'slotRollingAmount', label: '슬롯 롤링금' },
      //     { key: 'lowerSlotRollingAmount', label: '하위 슬롯 롤링금' },
      //     { key: 'sumSlotRollingAmount', label: '총 슬롯 롤링금' },
      //   ],
      // },
      fields: [
        { key: 'baseDate', label: '기준일자', thClass: '' },
        { key: 'betDate', label: '베팅일자', thClass: '' },
        { key: 'gameId', label: '게임id', thClass: '' },
        { key: 'gameType', label: '게임', thClass: '' },
        // { key: 'gameDetail', label: '게임상세', thClass: '' },
        { key: 'gameUserid', label: '베팅유저', thClass: '' },
        { key: 'issueDate', label: '정산일자', thClass: '' },
        { key: 'bettingAmount', label: '배팅금', thClass: '' },
        {
          key: 'rate', label: '요율', thClass: '', tdClass: 'text-center',
        },
        { key: 'rollingAmount', label: '롤링금', thClass: '' },
        {
          key: 'formula', label: '계산식', thClass: '', tdClass: 'text-left',
        },
      ],

      selfRollingData: [],
      rollingData: {},
    }
  },
  computed: {
    ...branchStore.mapGetters({
      fetchDailyBalanceRolling: 'fetchDailyBalanceRolling',
    }),
    // selfRollingData() {
    //   if (!this.fetchDailyBalanceRolling) return []
    //   if (!this.rolling || !this.rolling.userid) return []
    //   return this.fetchDailyBalanceRolling.filter(item => item.userid === this.rolling.userid)
    // },
    filteredItems() {
      let filteredData = this.fetchDailyBalanceRolling
        .filter(item => item.userid === this.rolling.userid)
        .sort((a, b) => new Date(b.betDate) - new Date(a.betDate))

      if (this.searchFilter) {
        filteredData = filteredData.filter(item => Object.values(item).some(
          val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase()),
        ))
      }

      return filteredData
    },
  },
  methods: {
    fetchData() {
      this.selfRollingData = this.fetchDailyBalanceRolling
        .filter(item => item.userid === this.rolling.userid)
        .sort((a, b) => new Date(b.betDate) - new Date(a.betDate))

      // 데이터 정렬
      const sortedData = [...this.fetchDailyBalanceRolling].sort((a, b) => {
        if (a.gameId !== b.gameId) {
          return b.gameId.localeCompare(a.gameId)
        }
        return b.seq - a.seq
      })

      // gameIdMap을 미리 선언
      const gameIdMap = new Map()

      // gameId를 키로 하는 Map에 데이터 추가
      sortedData.forEach(item => {
        if (!gameIdMap.has(item.gameId)) {
          gameIdMap.set(item.gameId, [])
        }
        gameIdMap.get(item.gameId).push(item)
      })

      this.rollingData = Object.fromEntries(gameIdMap)
    },
    toggleDetail(row, index, type) {
      console.log(type.target)
      // detail 속성이 없으면 추가, 있으면 제거
      this.$set(row, '_showDetails', !row._showDetails)
    },
    getRollingData(gameId) {
      return this.rollingData[gameId] || []
    },
    // [Formatter]
    formatNumberWithCommas(value) {
      if (value == null || Number.isNaN(Number(value))) {
        return value
      }
      return parseInt(value, 10).toLocaleString() // 정수로 변환 후 천 단위 쉼표 추가
    },
    getTotal(field) {
      return this.filteredItems.reduce((acc, item) => acc + item[field], 0)
    },

    // [Modal]
    btnModal(target) {
      if (target === 'show') {
        console.log(this.componentName, 'btnModal()', target)
        this.fetchData()
      }
      if (target === 'close') {
        console.log(this.componentName, 'btnModal()', target)
        this.$bvModal.hide(this.componentName)
      }
    },
  },
}
</script>
<style>
/* 모달너비 */
/* .modal-dialog {
    max-width: 850px !important;
} */
</style>
