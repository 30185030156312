<template>
  <div>
    <b-modal
      :id="componentName"
      no-close-on-backdrop
      hide-footer
      centered
      @shown="btnModal('show')"
      @hidden="btnModal('close')"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          루징금 상세
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click.stop="btnModal('close')"
          />
        </div>
      </template>

      <!-- Modal Body -->
      <b-card no-body>
        <div>
          <div style="display: table; border-collapse: collapse; width: 100%;">
            <!-- 헤더 -->
            <div style="display: table-row; font-weight: bold; border-bottom: 2px solid #b4b7bd;">
              <div style="display: table-cell; width: 32%; padding: 5px 12px; text-align: center; border-right: 1px dashed #b4b7bd;">
                유형
              </div>
              <div style="display: table-cell; width: 12%; padding: 5px 12px; text-align: center; border-right: 1px dashed #b4b7bd;">
                상위
              </div>
              <div style="display: table-cell; width: 9%; padding: 5px 12px; text-align: center; border-right: 1px dashed #b4b7bd;">
                충전
              </div>
              <div style="display: table-cell; width: 9%; padding: 5px 12px; text-align: center; border-right: 1px dashed #b4b7bd;">
                환전
              </div>
              <div style="display: table-cell; width: 9%; padding: 5px 12px; text-align: center; border-right: 1px dashed #b4b7bd;">
                요율
              </div>
              <div style="display: table-cell; width: 30%; padding: 5px 12px; text-align: center; border-right: 1px dashed #b4b7bd;">
                계산식
              </div>
              <div style="display: table-cell; width: 9%; padding: 5px 12px; text-align: center;">
                결과
              </div>
            </div>
            <!-- 자신의 루징 -->
            <div
              v-if="filteredItems && filteredItems.length"
              style="display: table-row;"
            >
              <div style="display: table-cell; padding: 5px 12px; border-right: 1px dashed #b4b7bd;">
                {{
                  losing.type === 1
                    ? '자신의 루징'
                    : losing.type === 2
                      ? '하위의 루징'
                      : '루징금 합계'
                }}
              </div>
              <div style="display: table-cell; padding: 5px 12px; border-right: 1px dashed #b4b7bd;" />
              <div style="display: table-cell; padding: 5px 12px; border-right: 1px dashed #b4b7bd;" />
              <div style="display: table-cell; padding: 5px 12px; border-right: 1px dashed #b4b7bd;" />
              <div style="display: table-cell; padding: 5px 12px; border-right: 1px dashed #b4b7bd;" />
              <div style="display: table-cell; padding: 5px 12px; border-right: 1px dashed #b4b7bd;" />
              <div style="display: table-cell; padding: 5px 12px; border-right:" />
            </div>
            <!-- 하위 사용자별 루징 (동적 행 생성) -->
            <div
              v-for="(child, index) in filteredItems"
              :key="index"
              style="display: table-row;"
            >
              <div style="display: table-cell; padding: 5px 12px; border-right: 1px dashed #b4b7bd;">
                <span style="margin-left: 10px">
                  {{ child.userid }} [ {{ child.nickname }} ]
                </span>
              </div>
              <div style="display: table-cell; padding: 5px 12px; border-right: 1px dashed #b4b7bd; text-align: center; ">
                {{ child.recommendNickname }}
              </div>
              <div style="display: table-cell; padding: 5px 12px; border-right: 1px dashed #b4b7bd; text-align: right; ">
                {{ formatNumberWithCommas(child.deposit) }}
              </div>
              <div style="display: table-cell; padding: 5px 12px; border-right: 1px dashed #b4b7bd; text-align: right; ">
                {{ formatNumberWithCommas(child.exchange) }}
              </div>
              <div style="display: table-cell; padding: 5px 12px; border-right: 1px dashed #b4b7bd; text-align: center; ">
                {{ child.rate }}
              </div>
              <div style="display: table-cell; padding: 5px 12px; border-right: 1px dashed #b4b7bd; text-align: right; ">
                {{ child.losingFormula }}
              </div>
              <div style="display: table-cell; padding: 5px 12px; text-align: right;">
                {{ formatNumberWithCommas(child.losing) }}
              </div>
            </div>
            <!-- 총 루징 합 -->
            <div style="display: table-row; border-top: 2px solid #b4b7bd; ">
              <div style="display: table-cell; padding: 5px 12px; font-size: 1.2em; ">
                총 루징 합
              </div>
              <div style="display: table-cell; padding: 5px 12px;" />
              <div style="display: table-cell; padding: 5px 12px;" />
              <div style="display: table-cell; padding: 5px 12px;" />
              <div style="display: table-cell; padding: 5px 12px;" />
              <div style="display: table-cell; padding: 5px 12px;" />
              <div style="display: table-cell; padding: 5px 12px; text-align: right; font-size: 1.2em">
                {{ formatNumberWithCommas(totalLosing) }}
              </div>
            </div>
          </div>
        </div>
        <hr>
      </b-card>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal,
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { createNamespacedHelpers } from 'vuex'

const branchStore = createNamespacedHelpers('branchStore')

export default {

  name: 'DailyBalanceLosing',
  components: {
    BModal,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    losing: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,
    }
  },
  computed: {
    ...branchStore.mapGetters({
      fetchDailyBalanceLosing: 'fetchDailyBalanceLosing',
    }),
    // 검색필터된 Items
    filteredItems() {
      let filteredData = []

      const detail1 = this.fetchDailyBalanceLosing.losingDetail || []
      const detail2 = this.fetchDailyBalanceLosing.lowerLosingDetail || []

      if (this.losing.type === 1) {
        filteredData = detail1
      }
      if (this.losing.type === 2) {
        filteredData = detail2
      }
      if (this.losing.type === 3) {
        filteredData = [...detail1, ...detail2]
      }
      return filteredData
    },
    fetchData() {
      return this.fetchDailyBalanceLosing
    },
    totalLosing() {
      return this.filteredItems.reduce((acc, cur) => acc + (cur.losing || 0), 0)
    },
  },
  methods: {
    // [Formatter]
    formatNumberWithCommas(value) {
      if (value == null || Number.isNaN(Number(value))) {
        return value
      }
      return parseInt(value, 10).toLocaleString() // 정수로 변환 후 천 단위 쉼표 추가
    },

    // [Modal]
    btnModal(target) {
      if (target === 'show') {
        console.log(this.componentName, 'btnModal()', target)
      }
      if (target === 'close') {
        console.log(this.componentName, 'btnModal()', target)
        this.$bvModal.hide(this.componentName)
      }
    },
  },
}
</script>
<style>
/* 모달너비 */
.modal-dialog {
    max-width: 900px !important;
}
</style>
